<template lang="pug">
.dependencies
    v-card.ml-8(light)
        v-card-title.brand.white--text.py-2 Software Dependencies
            v-spacer
            v-btn(title='Add new release dependency', icon, dense, color='white', @click="dialog=true")
                v-icon add
        v-card-text
            v-data-table( :headers="headers",  :items="allReleaseDeps", sort-by="Software" )
                template(v-slot:item.actions="{ item }")
                    v-icon( small, color="red", @click="deleteItem(item)").pl-2 delete

                template(v-slot:no-data)
                    v-btn( color="primary", @click="initialize" ) Refresh


            v-dialog(v-model='dialog', max-width='900px', light)
                v-card
                    v-card-title.brand.white--text New Release Dependency
                    v-card-text
                        v-container
                            v-form( ref="form" v-model="valid" )
                                v-row
                                    v-col.d-flex(cols="12" sm="6" md="4")
                                        v-combobox(
                                            label="Product",
                                            v-model="selectedRelease",
                                            :items="softwareNames",
                                            item-text="name"
                                            item-value="value"
                                            :rules="[rules.required]",
                                            color="brand"
                                        )
                                    v-col.d-flex(cols="12" sm="6" md="4")
                                        v-combobox(label="Version", v-model="selectedReleaseVersion", :items="allReleaseUniques.versions", :rules="[rules.required]", color="brand")
                                v-row
                                    v-col.d-flex
                                        h2.ml-3.brand--text Depends On
                                v-row
                                    v-col.d-flex(cols="12" sm="6" md="4")
                                        v-combobox(
                                            label="Dependency",
                                            v-model="selectedDependency",
                                            :items="dependencyNames",
                                            :rules="[rules.required]",
                                            color="brand"
                                        )
                                    v-col.d-flex(cols="12" sm="6" md="4")
                                        v-combobox(label="Version", v-model="selectedDependencyVersion", :items='allDependencyUniques.versions', :rules="[rules.required]", color="brand")
                                v-row
                                    v-col.d-flex(cols="12" md="8")
                                        v-file-input(label='Dependency File', v-model="selectedFile", :rules="[rules.required]", color="brand")
                    v-card-actions
                        v-spacer
                        v-btn.actions.orange.white--text(text, @click="close") Cancel
                        v-btn.actions.brand.white--text(text, :disabled="!valid", @click="save") Save
</template>



<script lang="js">

export default {
    name: 'Dependencies',
    data () {
        return {
            dialog: false,
            addResult: false,
            headers: [
                { text: 'Software', value: 'software', filterable: true},
                { text: 'Release Version', value: 'softwareVersion' },
                { text: 'Dependency', value: 'dependency' },
                { text: 'Version', value: 'dependencyVersion' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            valid: false,
            rules: {
                required: value => !!value || 'Required.',
                email: value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
                minLength: value => value && value.length >= 4 || 'Min 4 characters',
                duplicate: value => !this.checkUserExists(value) || 'Username already exists'
            },
            allReleaseDeps: [],
            allReleases: [],
            allDependencies: [],

            selectedFile: null,
            selectedDependency: null,
            selectedDependencyVersion: null,
            selectedRelease: null,
            selectedReleaseVersion: null,
            softwareNames: [
                {name: 'Infoscanjs', value: 'infoscanjs'},
                {name: 'SAT', value: 'sat'},
                {name: 'Central Server', value: 'centralserver'},
                {name: 'Customer Portal', value: 'customerportal'},
            ],
            dependencyNames: [
                'nodejs', 'mariadb', 'mongodb'
            ]

        };
    },
    computed: {
        allDependencyUniques () {
            if(!this.allDependencies.length) return [];

            let depUniques = {versions: []};

            this.allDependencies.map(dependency => dependency.version).forEach(version => {
                if (!depUniques.versions.includes(version)) {
                    depUniques.versions.push(version);
                }
            });

            return depUniques;
        },

        allReleaseUniques () {

            if(!this.allReleases.length) return [];

            let releaseUniques = {versions: []};

            this.allReleases.map(release => release.version).forEach(version => {
                if (!releaseUniques.versions.includes(version)) {
                    releaseUniques.versions.push(version);
                }
            });

            return releaseUniques;
        },
    },
    watch: {

    },
    mounted () {
        window.dependenciesvm = this;
        this.initialize();
    },
    methods: {
        initialize () {
            this.getReleaseDependencies();
            this.getAllReleases();
            this.getAllDependencies();
        },

        getReleaseDependencies () {
            this.socketEmit('getSoftwareDependencies', null, (results) => {
                this.allReleaseDeps = results || [];
            });
        },

        getAllReleases () {
            this.socketEmit('getAllReleases', null, (results) => {
                this.allReleases = results || [];
            });
        },

        getAllDependencies () {
            this.socketEmit('getAllDependencies', null, (results) => {
                this.allDependencies = results || [];
            });
        },

        async deleteItem (item) {
            this.allReleaseDeps.splice(item, 1);
            await this.socketEmit('deleteReleaseDependency', item.linkingTableId);
        },

        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save () {
            let newReleaseDep = {
                software: this.selectedRelease.value,
                softwareVersion: this.selectedReleaseVersion,
                dependency: this.selectedDependency,
                dependencyVersion: this.selectedDependencyVersion,
                dependencyFile: this.selectedFile.name,
            };
            console.log(newReleaseDep);
            this.socketEmit('addSoftwareDependency', newReleaseDep, (results) => {
                this.addResult = results || [];
                this.reset();
            });
        },
        reset () {
            this.dialog = false;
            this.$nextTick(() => {
                this.valid = false;
                this.selectedFile = null;
                this.selectedDependency = null;
                this.selectedDependencyVersion = null;
                this.selectedRelease = null;
                this.selectedReleaseVersion = null;
            });
            this.initialize();
        }
    }
};

</script>

<style lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>
